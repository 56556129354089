import React, { useState, useEffect, useCallback } from "react";
import ImageViewer from "./components/ImageViewer";
import { CD_floorData, YLT_floorData, restroomIds } from "./floorData";
function App() {
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [searchIds, setSearchIds] = useState([]);

  useEffect(() => {
    // 从 public 目录下获取数据
    const fetchData = async () => {
      const allLocations = await Promise.all(
        [...CD_floorData, ...YLT_floorData].map(async (floor) => {
          try {
            const response = await fetch(floor.jsonFile);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const annotations = await response.json();
            return { ...floor, annotations };
          } catch (error) {
            console.error(`Error fetching data for ${floor.floor}:`, error);
            return { ...floor, annotations: [] };
          }
        })
      );
      setLocations(allLocations);
    };

    fetchData().catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleSearchByIds = useCallback(
    (ids) => {
      const specialLocations = [];

      // 新增对 ylt-en-map-1, ylt-ja-map-1, ylt-en-map-2, ylt-ja-map-2 的处理
      const mapIds = [
        "ylt-en-map-1",
        "ylt-ja-map-1",
        "ylt-en-map-2",
        "ylt-ja-map-2",
        "cd-1f-en",
        "cd-1f-ja",
        "cd-2f-en",
        "cd-2f-ja",
        "cd-en-map",
        "cd-ja-map",
      ];
      mapIds.forEach((mapId) => {
        if (ids.includes(mapId)) {
          specialLocations.push({
            floor: "",
            imageFile: `/images/${mapId}.png`,
            annotations: [],
          });
        }
      });

      // 过滤 locations，返回符合条件的楼层信息
      const filtered = locations
        .map((location) => {
          // 获取当前楼层的卫生间ID列表
          const floorRestroomIds = restroomIds[location.floor] || [];
          // 过滤出与搜索ID匹配的注释
          const matchedAnnotations = location.annotations.filter((annotation) =>
            ids.includes(annotation.id)
          );
          // 如果匹配的ID超过3个，或者没有匹配的ID但有卫生间ID，则只展示卫生间
          if (matchedAnnotations.length > 3) {
            const restroomAnnotations = location.annotations.filter(
              (annotation) => floorRestroomIds.includes(annotation.id)
            );
            return { ...location, annotations: restroomAnnotations };
          }
          // 如果有匹配的ID，展示这些ID
          if (matchedAnnotations.length > 0) {
            return { ...location, annotations: matchedAnnotations };
          }
          // 如果没有匹配的ID且没有卫生间ID，返回null
          return null;
        })
        .filter(
          // 过滤掉没有注释的楼层
          (location) => location !== null
        );

      // 合并特殊位置和过滤后的楼层信息
      setFilteredLocations([...specialLocations, ...filtered]);
    },
    [locations] // 依赖于 locations
  );

  useEffect(() => {
    // 解析 URL 查询参数
    const params = new URLSearchParams(window.location.search);
    const ids = params.get("ids");
    if (ids) {
      const idArray = ids.split(",");
      setSearchIds(idArray);
      handleSearchByIds(idArray);

      // 设置页面标题
      if (idArray.some((id) => id.startsWith("YLT"))) {
        document.title = "ルミネ有楽町";
      } else {
        document.title = "ルミネ池袋";
      }
    }
  }, [locations, handleSearchByIds]);

  return (
    <div className="app-container">
      <div className="image-list">
        {filteredLocations.map((location) => (
          <ImageViewer
            key={location.floor}
            location={location}
            keywords={searchIds}
          />
        ))}
      </div>
    </div>
  );
}

export default App;
