export const CD_floorData = [
  {
    floor: "ルミネ池袋-B1F",
    jsonFile: "/data/cd/B1F.json",
    imageFile: "/images/cd/B1.gif",
    imageWidth: 1400,
    imageHeight: 800,
  },
  {
    floor: "ルミネ池袋-1F",
    jsonFile: "/data/cd/1F.json",
    imageFile: "/images/cd/1.gif",
    imageWidth: 1400,
    imageHeight: 800,
  },
  {
    floor: "ルミネ池袋-2F",
    jsonFile: "/data/cd/2F.json",
    imageFile: "/images/cd/2.gif",
    imageWidth: 1400,
    imageHeight: 800,
  },
  {
    floor: "ルミネ池袋-3F",
    jsonFile: "/data/cd/3F.json",
    imageFile: "/images/cd/3.gif",
    imageWidth: 1400,
    imageHeight: 900,
  },
  {
    floor: "ルミネ池袋-4F",
    jsonFile: "/data/cd/4F.json",
    imageFile: "/images/cd/4.gif",
    imageWidth: 1400,
    imageHeight: 900,
  },
  {
    floor: "ルミネ池袋-5F",
    jsonFile: "/data/cd/5F.json",
    imageFile: "/images/cd/5.gif",
    imageWidth: 1400,
    imageHeight: 800,
  },
  {
    floor: "ルミネ池袋-6F",
    jsonFile: "/data/cd/6F.json",
    imageFile: "/images/cd/6.gif",
    imageWidth: 1400,
    imageHeight: 900,
  },
  {
    floor: "ルミネ池袋-7F",
    jsonFile: "/data/cd/7F.json",
    imageFile: "/images/cd/7.gif",
    imageWidth: 1400,
    imageHeight: 800,
  },
  {
    floor: "ルミネ池袋-8F",
    jsonFile: "/data/cd/8F.json",
    imageFile: "/images/cd/8.gif",
    imageWidth: 1400,
    imageHeight: 1300,
  },
  {
    floor: "ルミネ池袋-9F",
    jsonFile: "/data/cd/9F.json",
    imageFile: "/images/cd/9.gif",
    imageWidth: 1400,
    imageHeight: 800,
  },
  {
    floor: "ルミネ池袋-10F",
    jsonFile: "/data/cd/10F.json",
    imageFile: "/images/cd/10.gif",
    imageWidth: 1400,
    imageHeight: 800,
  },
];
export const YLT_floorData = [
  {
    floor: "ルミネ有楽町-B1F",
    jsonFile: "/data/ylt/B1F.json",
    imageFile: "/images/ylt/B1.gif",
    imageWidth: 1400,
    imageHeight: 900,
  },
  {
    floor: "ルミネ有楽町-B2F",
    jsonFile: "/data/ylt/B2F.json",
    imageFile: "/images/ylt/B2.gif",
    imageWidth: 1400,
    imageHeight: 700,
  },
  {
    floor: "ルミネ有楽町-1F",
    jsonFile: "/data/ylt/1F.json",
    imageFile: "/images/ylt/1.gif",
    imageWidth: 1400,
    imageHeight: 1000,
  },
  {
    floor: "ルミネ有楽町-2F",
    jsonFile: "/data/ylt/2F.json",
    imageFile: "/images/ylt/2.gif",
    imageWidth: 1400,
    imageHeight: 900,
  },
  {
    floor: "ルミネ有楽町-3F",
    jsonFile: "/data/ylt/3F.json",
    imageFile: "/images/ylt/3.gif",
    imageWidth: 1400,
    imageHeight: 900,
  },
  {
    floor: "ルミネ有楽町-4F",
    jsonFile: "/data/ylt/4F.json",
    imageFile: "/images/ylt/4.gif",
    imageWidth: 1400,
    imageHeight: 900,
  },
  {
    floor: "ルミネ有楽町-5F",
    jsonFile: "/data/ylt/5F.json",
    imageFile: "/images/ylt/5.gif",
    imageWidth: 1400,
    imageHeight: 1000,
  },
  {
    floor: "ルミネ有楽町-6F",
    jsonFile: "/data/ylt/6F.json",
    imageFile: "/images/ylt/6.gif",
    imageWidth: 1400,
    imageHeight: 1000,
  },
  {
    floor: "ルミネ有楽町-7F",
    jsonFile: "/data/ylt/7F.json",
    imageFile: "/images/ylt/7.gif",
    imageWidth: 1400,
    imageHeight: 1000,
  },
  {
    floor: "ルミネ有楽町-8F",
    jsonFile: "/data/ylt/8F.json",
    imageFile: "/images/ylt/8.gif",
    imageWidth: 1400,
    imageHeight: 900,
  },
];
export const restroomIds = {
  "ルミネ池袋-B1F": ["CD-205", "CD-206"],
  "ルミネ池袋-1F": [],
  "ルミネ池袋-2F": ["CD-10", "CD-11"],
  "ルミネ池袋-3F": ["CD-26"],
  "ルミネ池袋-4F": ["CD-52"],
  "ルミネ池袋-5F": ["CD-81", "CD-82"],
  "ルミネ池袋-6F": ["CD-112", "CD-113"],
  "ルミネ池袋-7F": ["CD-126"],
  "ルミネ池袋-8F": ["CD-148", "CD-149", "CD-172", "CD-173"],
  "ルミネ池袋-9F": ["CD-186", "CD-187"],
  "ルミネ池袋-10F": ["CD-202", "CD-203"],
  "ルミネ有楽町-B1F": ["YLT-3", "YLT-4", "YLT-28", "YLT-29"],
  "ルミネ有楽町-B2F": [],
  "ルミネ有楽町-1F": [],
  "ルミネ有楽町-2F": ["YLT-98", "YLT-99"],
  "ルミネ有楽町-3F": ["YLT-75", "YLT-76"],
  "ルミネ有楽町-4F": ["YLT-98", "YLT-99"],
  "ルミネ有楽町-5F": [],
  "ルミネ有楽町-6F": ["YLT-116", "YLT-117"],
  "ルミネ有楽町-7F": [],
  "ルミネ有楽町-8F": ["YLT-148", "YLT-149"],
};
