import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
// 确保你已经安装了 Font Awesome
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faSearchPlus,
//   faSearchMinus,
//   faUndo,
// } from "@fortawesome/free-solid-svg-icons";

function ImageViewer({ location, keywords }) {
  return (
    <div className="image-container">
      <TransformWrapper
        defaultScale={1}
        wheel={{ step: 0.1 }}
        doubleClick={{ disabled: true }}
      >
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>
            {/* <div className="controls">
              <button
                onClick={() => {
                  console.log("Zoom In");
                  zoomIn();
                }}
              >
                <FontAwesomeIcon icon={faSearchPlus} />
              </button>
              <button
                onClick={() => {
                  console.log("Zoom Out");
                  zoomOut();
                }}
              >
                <FontAwesomeIcon icon={faSearchMinus} />
              </button>
              <button
                onClick={() => {
                  console.log("Reset");
                  resetTransform();
                }}
              >
                <FontAwesomeIcon icon={faUndo} />
              </button>
            </div> */}

            <div className="image-wrapper">
              <div className="floor-label">{location.floor}</div>
              <TransformComponent>
                <img src={location.imageFile} alt={location.floor} />
                {location.annotations
                  .filter((annotation) => keywords.includes(annotation.id))
                  .map((annotation, index) => {
                    const xPercent = (annotation.x / location.imageWidth) * 100;
                    const yPercent =
                      (annotation.y / location.imageHeight) * 100;
                    return (
                      <div
                        key={index}
                        className="annotation"
                        style={{
                          left: `${xPercent}%`,
                          top: `${yPercent}%`,
                        }}
                        title={annotation.name}
                      ></div>
                    );
                  })}
              </TransformComponent>
            </div>
          </>
        )}
      </TransformWrapper>
    </div>
  );
}

export default ImageViewer;
